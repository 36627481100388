/* eslint-disable max-lines-per-function, max-statements, no-console, no-extra-parens */
/* eslint-disable react/jsx-no-bind */
import { ArrowRightIcon, CheckCircleIcon } from "@heroicons/react/24/solid";
import { Button, Container, Row, Spinner, Stack } from "react-bootstrap";
import { Element, scroller } from "react-scroll";
import { getProfileProgress, transformAnswers, transformResponsesToAnswers } from "../../helpers/respondents";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AssessmentData from "../../liaStatements/statements_en.json";
import AssessmentQuestion from "./AssessmentQuestion";
import EqqoVideo from "./EqqoVideo";
import { difference } from "lodash";
import responsesMutation from "./responsesMutation";
import { useMutation } from "react-query";
import { useTimer } from "react-timer-hook";
import { useUser } from "../UserContext";

const Assessment = () => {
	const { id } = useParams();
	const { user, respondent } = useUser();
	const navigate = useNavigate();
	// Navigation vars
	const isFinalPage = useMemo(() => parseInt(id, 10) === Object.keys(AssessmentData.scenarios).length, [id]);
	const forwardUrl = useMemo(() => (isFinalPage ? "/profile/assessment/complete" : `/profile/assessment/${Number(id) + 1}`), [id]);
	const buttonLabel = useMemo(() => (isFinalPage ? "Submit My Responses" : "Next"), [id]);
	// Assessment question loading and handling
	const scenarioData = useMemo(() => AssessmentData.scenarios[id], [id]);
	const { responseLabels } = AssessmentData;
	const questionArray = Object.entries(scenarioData.questions).map((question) => (
		{
			id: question[1].statement_id,
			response: null,
			text: question[1].statement
		}
	));
	const [answers, setAnswers] = useState(respondent?.weqProfile.profileResponses
		? transformResponsesToAnswers(respondent?.weqProfile.profileResponses, questionArray)
		: {});
	const [activeQuestion, setActiveQuestion] = useState(0);
	const [readScenario, setReadScenario] = useState(false);
	// Track if video is finished, i.e. has been watched
	const [videoFinished, setVideoFinished] = useState(false);
	// Control if questions can be answered or not (must watch video first)
	const [canAnswer, setCanAnswer] = useState(false);
	// Minimum time that needs to elapse before user can answer questions
	const minTime = 10000;
	const [timeoutMinTime, setTimeoutMinTime] = useState(false);
	const onVideoProgress = useCallback((progress) => {
		if (progress.played >= 0.99) {
			setVideoFinished(true);
		}
	}, []);
	// Handle submitting reponses to the backend
	const {
		isLoading,
		mutate
	} = responsesMutation(useMutation, {
		onError: (error) => {
			console.log(error);
		},
		onSuccess: ({ data }) => {
			const {
				profileResponses,
				weqProfileAcfFields: { profilestatus }
			} = data.data.updateWeqProfile.weqProfile;
			const newRespondent = {
				...respondent,
				weqProfile: {
					...respondent?.weqProfile,
					profileResponses,
					profileStatus: profilestatus
				},
				...getProfileProgress(profileResponses, AssessmentData.scenarios)
			};
			console.log(newRespondent);

			setActiveQuestion(0);
			setCanAnswer(false);
			setReadScenario(false);
			setVideoFinished(false);
			setAnswers({});

			/**
			 * Should update respondent in UserContext
			 * setRespondent(newRespondent);
			 * addNotification({
			 * message: "Responses submitted successfully.",
			 * type: "success"
			 * });
			 */
			navigate(forwardUrl);
			scroller.scrollTo("scenario");
		}
	});
	const setNextActiveQuestion = (newAnswers) => {
		if (Object.values(newAnswers).length < questionArray.length) {
			let idx = 0;
			while (newAnswers[questionArray[idx]?.id]) {
				// eslint-disable-next-line no-plusplus
				idx++;
			}
			setActiveQuestion(idx);
		} else {
			setActiveQuestion(null);
		}
	};
	const updateQuestions = (value, { target }) => {
		const questionid = parseInt(target.name.split("-").pop(), 10);
		const newAnswers = {
			...answers,
			[questionid]: parseInt(value, 10)
		};
		setAnswers(newAnswers);
		setNextActiveQuestion(newAnswers);
	};
	const handleSubmit = useCallback(() => {
		const answeredQuestions = Object.values(answers).length;
		const totalQuestions = questionArray.length;

		if (answeredQuestions === totalQuestions) {
			const mutationData = {
				id: user.weqProfileId,
				profileResponses: transformAnswers(answers, user.weqProfileId)
			};
			mutate(mutationData);
		} else {
			const answeredIds = Object.keys(answers).map((key) => parseInt(key, 10));
			// eslint-disable-next-line camelcase
			const questionIds = Object.values(scenarioData.questions).map(({ statement_id }) => parseInt(statement_id, 10));
			const unansweredIds = difference(questionIds, answeredIds).sort();
			const [firstUnanswered] = unansweredIds;
			// Scroll to first unanswered question.
			scroller.scrollTo(`question-${firstUnanswered}`, {
				offset: -240
			});
		}
	}, [answers, mutate, scenarioData.questions, user.weqProfileId, questionArray]);
	useTimer({
		autoStart: true,
		expiryTimestamp: new Date().getTime() + minTime,
		onExpire: () => {
			setTimeoutMinTime(true);
		}
	});
	useEffect(() => {
		if (readScenario) {
			scroller.scrollTo("video");
		}
	}, [readScenario]);
	useEffect(() => {
		if (canAnswer) {
			scroller.scrollTo("statements", {
				offset: -64
			});
		}
	}, [canAnswer]);

	return (
		<Container className="assessment-view bg-white rounded-3">
			<Row className="assessment-section assessment-about">
				<Element className={`assessment-section__header ${ readScenario ? 'text-success' : 'text-primary'}`} name="scenario">
					<h2 className="fs-24">1. About the Scenario</h2>
					{ readScenario && (<>
						<CheckCircleIcon height={24} width={24} />
						<p className="fs-18 fw-semibold">Read</p>
					</>
					)}
				</Element>
				<div className="assessment-section__content" hidden={readScenario}>
					<h3 className="fs-18 text-eqqo-dark-gray">Your Role</h3>
					<p className="fs-18 text-eqqo-dark-gray">{scenarioData.scenario.roles}</p>
					<h3 className="fs-18 text-eqqo-dark-gray">The Situation</h3>
					<p className="fs-18 text-eqqo-dark-gray">{scenarioData.scenario.situation}</p>
					<div className="assessment-actions">
						<Button
							className="btn-gradient w-100"
							onClick={() => {
								setReadScenario(true);
							}}
						>
							Continue <ArrowRightIcon height={20} width={20} />
						</Button>
					</div>
				</div>
			</Row>
			<hr />
			<Row className="assessment-section assessment-video">
				<Element className={`assessment-section__header ${readScenario ? "" : "opacity-50 mb-0"} ${canAnswer ? "text-success" : "text-primary"}`} name="video">
					<h2 className="fs-24">2. Video</h2>
					{ !readScenario && (
						<p className="fs-18 fw-semibold text-eqqo-dark-gray">Watch Video After Reading About Scenario</p>
					)}
					{ canAnswer && ( <>
						<CheckCircleIcon height={24} width={24} />
						<p className="fs-18 fw-semibold">Watched</p>
					</>
					)}
				</Element>
				<div className="assessment-section__content" hidden={!readScenario || canAnswer}>
					<div className="video" data-video-id={scenarioData.videoId}>
						<EqqoVideo
							className="d-block w-100 mw-100 rounded-2 overflow-hidden"
							config={{
								wistia: {
									options: {
										playbackRateControl: false,
										playbar: process.env.REACT_APP_SHOW_SCROLL_BAR || false,
										playerColor: "000000",
										plugins: {
											"captions-v1": {
												onByDefault: true
											}
										},
										preload: true,
										videoFoam: true
									}
								}
							}}
							onProgress={onVideoProgress}
							style={{ aspectRatio: "16 / 9" }}
							url={`https://fast.wistia.net/embed/iframe/${scenarioData.videoId}`}
						/>
					</div>
					<div className="assessment-actions" hidden={!readScenario}>
						<Button
							className="btn-gradient w-100"
							disabled={!videoFinished || !timeoutMinTime}
							onClick={() => {
								setCanAnswer(true);
								scroller.scrollTo("statements");
							}}
						>
							I Have Watched the Video - Rate My Experience <ArrowRightIcon height={20} width={20} />
						</Button>
					</div>
				</div>
			</Row>
			<hr />
			<Row className="assessment-section assessment-statements">
				<Element className={`assessment-section__header text-primary ${canAnswer ? "" : "opacity-50 mb-0"}`} name="statements">
					<h2 className="fs-24">3. Rate Each Statement</h2>
					{ !canAnswer && (
						<p className="fs-18 fw-semibold text-eqqo-dark-gray">Rate Experience After Watching Video</p>
					)}
				</Element>
				<div className="assessment-section__content" hidden={!canAnswer}>
					<Stack
						style={{
							gap: "2rem",
							paddingInline: "0 !important"
						}}>
						{questionArray.map((ques, idx) => (
							<Element key={ques.id} name={`question-${ques.id}`}>
								<AssessmentQuestion
									active={idx === activeQuestion}
									answer={answers[ques.id]}
									disabled={!canAnswer}
									key={ques.id}
									question={ques}
									responseLabels={responseLabels}
									skipped={parseInt(Object.keys(answers).pop() || 0, 10) > parseInt(ques.id, 10) && !answers[ques.id]}
									updateQuestions={updateQuestions}
								/>
							</Element>
						))}
					</Stack>
				</div>
			</Row>
			<hr hidden={!canAnswer} />
			<div className="assessment-actions" hidden={!canAnswer}>
				<Button
					className="btn-gradient w-100"
					disabled={isLoading}
					onClick={handleSubmit}
					type="submit"
					variant="primary"
				>
					{
						isLoading
							? <Spinner animation="border" size="sm" />
							: <> {buttonLabel} <ArrowRightIcon height={20} width={20} /></>
					}
				</Button>
			</div>
		</Container>
	);
};

export default Assessment;
