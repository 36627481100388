/* eslint-disable max-len, max-statements, no-unused-vars, no-console */
/* eslint-disable react/jsx-max-depth, react/no-unescaped-entities */
import { Accordion, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useEffect, useMemo } from "react";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import AssessmentData from "../../liaStatements/statements_en.json";
import DownloadReportButton from "../../pages/practitioner/components/DownloadReportButton";
import Instructions from "./Instructions";
import JournalPrompts from "./JournalPrompts";
import { NavLink } from "react-router-dom";
import faqs from "./faqs";
import { getProfileProgress } from "../../helpers/respondents";
import { useQueryClient } from "react-query";
import { useUser } from "../UserContext";

const Dashboard = () => {
	const { user, respondent } = useUser();

	const queryClient = useQueryClient();
	useEffect(() => {
		/*
		 * Always refresh respondent when we load the user
		 * this prevents stale data preventing proper resuming of progress
		 * in the assessment as the user navigates around the app
		 */
		queryClient.invalidateQueries(["respondent", user.respondentId]);
	}, [user]);

	const progress = useMemo(() => ({ ...getProfileProgress(respondent?.weqProfile?.profileResponses || [], AssessmentData.scenarios) }), [respondent]);
	const profileCompleted = useMemo(() => respondent?.profilecomplete || respondent?.weqProfile.profilestatus === "completed", [respondent]);

	return (
		<Container className="bg-white rounded-3">
			{ respondent && !profileCompleted &&
				<Instructions readInstructions={respondent?.weqProfile.readInstructions} /> }
			{ respondent?.weqProfile.readInstructions && !profileCompleted && (
				<NavLink className="btn btn-gradient w-100 mt-4 w-100" to={`/profile/assessment/${progress.nextScenario}`}>
					Continue Profile <ArrowRightIcon height={24} width={24} />
				</NavLink>
			)}
			{ profileCompleted && (
				<div className="profile__dashboard">
					<Row>
						<Col md={6}>
							<Container className="bg-white border h-100 rounded-3">
								<h2>Access your EQQO</h2>
								<DownloadReportButton
									className="btn btn-primary w-100 mt-4 w-100"
									name={`${user?.firstName} ${user?.lastName}`}
									pdfUrl={user?.weqProfilePdfUrl}
									profileId={user?.weqProfileId}
								>
									Download Your EQQO Profile Report (PDF)
								</DownloadReportButton>
							</Container>
						</Col>
						<Col md={6}>
							<Container className="bg-white border h-100 rounded-3">
								<h2>Transaction History</h2>
								<div className="recent-transactions">
									<p className="transaction">MM/DD/YYYY..................................$160.00</p>
								</div>
								<NavLink to="/">Download Receipt/Invoice</NavLink>
							</Container>
						</Col>
					</Row>
					<Row className="mt-2">
						<Col>
							<Container className="bg-white border h-100 rounded-3">
								<h2>Go Deeper with Your EQQO</h2>
								<p>You have three options to go deeper with the EQQO</p>

								<Container className="bg-body-secondary rounded-3">
									<h3>Discover Deeper Insights with a 1:1 Debrief Session</h3>
									<p>Spend 60 minutes with a Certified EQQO Practitioner who will walk you through the key dimensions of your profile—what they reveal and how they show up in your everyday behavior. You’ll gain fresh, eye-opening perspectives on your default patterns and walk away with a clearer, more compassionate understanding of yourself.</p>
									<NavLink className="btn btn-primary w-100" to="/">Learn More About Debriefs</NavLink>
								</Container>

								<Container className="bg-body-secondary mt-4 rounded-3">
									<h3>Keep Growing with the EQQO Profile Handbook</h3>
									<p>Designed as your next step in the EQQO journey, the Handbook offers practical ways to apply what you've learned—personally and professionally. It includes 300 specific, daily practices organized by dimension, making it a powerful tool to support ongoing development and deeper relationships.</p>
									<div className="btn-group d-flex">
										<NavLink className="btn btn-primary" to="/">Order on Amazon</NavLink>
										<NavLink className="btn btn-primary" to="/">Purchase PDF Download</NavLink>
									</div>
								</Container>

								<Container className="bg-body-secondary mt-4 rounded-3">
									<h3>Understand Yourself in Relationship with Our On-Demand Mini-Course</h3>
									<p>“You in Relationship” is a self-paced video course that helps you take your EQQO insights even further. Through short, thoughtful videos, you’ll explore each part of the profile and learn how to interpret your results in the context of how you relate to others—at work, at home, and beyond.</p>
									<NavLink className="btn btn-primary w-100" to="/">Learn More about EQQO in Relationship</NavLink>
								</Container>
							</Container>
						</Col>
					</Row>
					<Row className="mt-2">
						<Col>
							<Container className="bg-white border h-100 rounded-3">
								<Accordion defaultActiveKey="0">
									<Accordion.Item eventKey="1">
										<Accordion.Header as="h2">Explore Journal Prompts</Accordion.Header>
										<Accordion.Body>
											<JournalPrompts />
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							</Container>
						</Col>
					</Row>
					<Row className="mt-2">
						<Col>
							<Container className="bg-white border h-100 rounded-3">
								<h2>Frequently Asked Questions</h2>
								<ol className="faqs mt-4">
									{faqs.map(({ question, answer, number }) => (<li key={`faq_${number}`}>
										<p>{question}</p>
										<p>{answer}</p>
									</li>))}
								</ol>
								<p className="alert alert-info mb-0 mt-5 text-center">If you still have questions or need help with anything, please email support@EQQOProfile.com</p>
							</Container>
						</Col>
					</Row>
				</div>)}
		</Container>
	);
};

export default Dashboard;
