/* eslint-disable camelcase, capitalized-comments, max-statements, no-console, prefer-named-capture-group */
/* eslint-disable react/no-unescaped-entities, react/jsx-no-bind */
import * as yup from "yup";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useCallback, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Formik } from "formik";
import { stateCodes } from "./stateCodes";
import { useNavigate } from "react-router";

import purchaseSingleCredit from "./purchaseSingleCreditMutation";
import { useAuth } from "../services/eqqo.auth.service";

const initialValues = {
	billingCity: "",
	billingCountry: "",
	billingState: "",
	billingStreet: "",
	billingZip: "",
	name: ""
};

const validationSchema = yup.object({
	billingCity: yup.string().required("City is requried."),
	billingCountry: yup.string().required("Country is required."),
	billingState: yup.string().
		max(2).
		required("State is required"),
	billingStreet: yup.string().required("Street address is required"),
	billingZip: yup.string().required("Zip Code is requried"),
	name: yup.string().required("Name is required")
});

const PaymentForm = () => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { user } = useAuth();
	const { mutateAsync: purchaseCredit } = purchaseSingleCredit(useMutation, user.databaseId);

	const [loading, setLoading] = useState(false);

	const stripe = useStripe();
	const elements = useElements();

	const handleFormSubmit = useCallback(async (values) => {
		if (!stripe || !elements) {
			return null;
		}
		setLoading(true);

		try {
			/* eslint-disable sort-keys */
			const token = await stripe.createToken(
				elements.getElement(CardElement),
				{
					name: values.name,
					address_line1: values.billingStreet,
					address_city: values.billingCity,
					address_state: values.billingState,
					address_zip: values.billingZip,
					address_country: values.billingCountry
				}
			);
			/* eslint-enable sort-keys */
			if (token.error) {
				throw new Error(token.error.message);
			}
			const newSingleCreditPurchase = await purchaseCredit(token.id);
			console.log(newSingleCreditPurchase);

			await queryClient.invalidateQueries(["respondent", user.respondentId]);
			// Confirm if successful, then...
			navigate("/sign-up/pay/success");
		} catch (error) {
			console.log(error);
		}

		setLoading(false);

		return null;
	}, [stripe, elements]);

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleFormSubmit}
			validationSchema={validationSchema}
		>
			{(formik) => (
				<Form className="flow" noValidate onSubmit={formik.handleSubmit}>
					<h2 className="mb-4">Card Information</h2>
					<Row>
						<Col>
							<Form.Label>Name on Card</Form.Label>
							<Form.Control
								isInvalid={Boolean(formik.errors.name)}
								name={"name"}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								type="text"
							/>
							<Form.Control.Feedback name={"name"} type="invalid">
								{formik.errors.name ? <p>{formik.errors.name}</p> : null}
							</Form.Control.Feedback>
						</Col>
					</Row>
					<Row>
						<Col>
							<CardElement className="stripe-card-input" />
						</Col>
					</Row>
					<hr />
					<h2 className="mb-4">Billing Address</h2>
					<Row>
						<Col>
							<Form.Label>Country</Form.Label>
							<Form.Select
								isInvalid={Boolean(formik.errors.billingCountry)}
								name={"billingCountry"}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
							>
								<option>Choose One</option>
								<option>United States</option>
								<option>Canada</option>
							</Form.Select>
							<Form.Control.Feedback name={"billingCountry"} type="invalid">
								{formik.errors.billingCountry ? <p>{formik.errors.billingCountry}</p> : null}
							</Form.Control.Feedback>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Label>Street Address</Form.Label>
							<Form.Control
								isInvalid={Boolean(formik.errors.billingStreet)}
								name={"billingStreet"}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								type="text"
							/>
							<Form.Control.Feedback name={"billingStreet"} type="invalid">
								{formik.errors.billingStreet ? <p>{formik.errors.billingStreet}</p> : null}
							</Form.Control.Feedback>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Label>City</Form.Label>
							<Form.Control
								isInvalid={Boolean(formik.errors.billingCity)}
								name={"billingCity"}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								type="text"
							/>
							<Form.Control.Feedback name={"billingCity"} type="invalid">
								{formik.errors.billingCity ? <p>{formik.errors.billingCity}</p> : null}
							</Form.Control.Feedback>
						</Col>
						<Col>
							<Form.Label>State</Form.Label>
							<Form.Select
								isInvalid={Boolean(formik.errors.billingState)}
								name={"billingState"}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								type="text"
							>
								<option>Choose One</option>
								{stateCodes.map((state) => 
									<option key={state.abbreviation} value={state.abbreviation}>{state.abbreviation}</option>
								)}
							</Form.Select>
							<Form.Control.Feedback name={"billingState"} type="invalid">
								{formik.errors.billingState ? <p>{formik.errors.billingState}</p> : null}
							</Form.Control.Feedback>
						</Col>
						<Col>
							<Form.Label>Zip</Form.Label>
							<Form.Control
								isInvalid={Boolean(formik.errors.billingZip)}
								name={"billingZip"}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								type="text"
							/>
							<Form.Control.Feedback name={"billingZip"} type="invalid">
								{formik.errors.billingZip ? <p>{formik.errors.billingZip}</p> : null}
							</Form.Control.Feedback>
						</Col>
					</Row>
					<Button className="btn-gradient w-100" disabled={loading} type="submit" variant="primary">{
						loading
							? <Spinner animation="border" />
							: "Make Payment"
					}
					</Button>
				</Form>
			)}
		</Formik>
	);
};

export default PaymentForm;
