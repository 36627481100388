/* eslint-disable */
import { Container, Col, FormCheck, Row } from "react-bootstrap";
import { useCallback, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import Helmet from "react-helmet";
import PaymentHistory from "./PaymentHistory";
import { useUser } from "../UserContext";
import saveUserProfile from "../sign-up/saveProfileMutation";

const MyAccount = () => {
	const { user, userProfile } = useUser();

	const queryClient = useQueryClient();
	const {
		isLoading,
		mutateAsync
	} = saveUserProfile(
		useMutation,
		user.databaseId,
		{
			onSuccess: async () => {
				await queryClient.refetchQueries("userProfile");
				console.log(userProfile);
			}
		}
	);

	const setCommsPref = useCallback(async (event) => {
		const pref = event.target.checked;
		try {
			await mutateAsync({ userProfile: { "communications": `${pref}` } });
		} catch (error) {
			console.log(error);
		}
	});

	return (
		<Container className="bg-white rounded-3">
			<Helmet>
				<title>{"My EQQO Account"}</title>
			</Helmet>
			<Row>
				{/* Terrible markup follows, but for visual demonstration purposes... */}
				<Col md={6}>
					<h4>Name</h4>
					<p>{ `${userProfile?.firstName} ${userProfile?.lastName}` }</p>
				</Col>
				<Col md={6}>
					<h4>Email</h4>
					<p>{userProfile?.email}</p>
				</Col>
				<Col md={6}>
					<h4>Newsletter</h4>
					<FormCheck id="newsletter-opt-in" label="Subscribe to our awesome newsletter" name="newsletter" onChange={setCommsPref} type="switch" checked={userProfile?.communications[0]} />
				</Col>
			</Row>
			<Row className="mt-5">
				<PaymentHistory />
			</Row>
		</Container>
	);
}

export default MyAccount;
