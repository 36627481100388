/* eslint-disable max-len */
/* eslint-disable max-lines-per-function, max-statements, no-console, no-extra-parens */
/* eslint-disable react/jsx-no-bind, react/no-unescaped-entities */
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useUser } from "../UserContext";

const AssessmentComplete = () => {
	const { user } = useUser();
	const queryClient = useQueryClient();
	useEffect(() => {
		/*
		 * Refresh respondent so we avoid a flash of instructions
		 */
		queryClient.invalidateQueries(["respondent", user.respondentId]);
	}, [user]);
	
	return (<Container className="assessment-complete bg-white border rounded-3">
		<h2 className="fs-24">Congratulations!</h2>
		<p>You've completed the EQQO Profile</p>

		<Container className="alert alert-info">
			<p>
				Change begins with awareness, and by taking this time for self-reflection, you’ve already set yourself apart. It’s not always easy to pause and look inward, and while conflict is rarely comfortable it often serves as the catalyst for lasting and impactful change.
			</p>

			<p>We encourage you to engage with the results of your EQQO Profile with an open, curious mind.</p>
			
			<p>As you explore your report, please keep in mind that your EQQO Profile results reflect your reported internal experience <strong>under stress</strong> in relationships, not necessarily how you may normally behave most of the time.</p>
			
			<p>It is intended to be a snapshot of how you reported your inner experience <strong>during conflict</strong>.</p>
			
			<p>It’s common for participants to find their EQQO Profile results surprising, or in some cases confronting. And that’s ok.</p>
			
			<p>
				The way we interpret our results can offer valuable insights into who we are. We encourage you to explore their meaning and uncover the lessons they hold for you. For many, they provide a level of awareness and understanding for instinctual behaviors of which we were previously unaware.
			</p>
			
			<p>Of course, if you’d like more guidance on your profile, we’re also happy to connect you with one of our certified practitioners who can help you best unpack and apply the learnings the EQQO holds for you.</p>
		</Container>

		<NavLink className="btn btn-primary mt-4 w-100" to="/profile/dashboard">Continue to Your EQQO Profile <ArrowRightIcon height={20} width={20} /></NavLink>
	</Container>)
};

export default AssessmentComplete;
