/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */

import { Col, Container, Row } from "react-bootstrap";
import CheckmarkIcon from "../../icons/CheckmarkIcon";
import Helmet from "react-helmet";
import { NavLink } from "react-router-dom";

const PaymentSuccess = () => (
	<Row as="main" className="sign-up">
		<Helmet>
			<title>
				{"EQQO Account Created"}
			</title>
		</Helmet>

		<Col className="create-account flow" fluid="true">
			<img
				alt="LOGO" className="logo" height="152" src="/eqqo_logo_blue.png"
				width="472"
			/>
			<Container className="bg-white flow wrapper text-center">
				<CheckmarkIcon background={"var(--bs-success"} />
				<h1 className="my-4">Payment Successful</h1>
				<NavLink className="btn btn-gradient" to="/profile/dashboard">Continue</NavLink>
			</Container>
		</Col>
	</Row>
);

export default PaymentSuccess;
