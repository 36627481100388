/* eslint-disable max-len, sort-keys */
export default [
	{
		number: 1,
		question: "Can I retake the EQQO Profile?",
		answer: "Retaking the profile too soon may be less valid, as familiarity can unintentionally influence results. For these reasons, we recommend waiting at least a year before retaking the profile unless one of these factors applies. If you choose to retake it, the profile must be repurchased."
	},
	{
		number: 2,
		question: "What is the EQQO Profile based on?",
		answer: ""
	}
]