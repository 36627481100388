import AssessmentQuestionScale from "./AssessmentQuestionScale";
import PropTypes from "prop-types";
import classNames from "classnames";

const AssessmentQuestion = ({ active, answer, disabled, question, responseLabels, skipped, updateQuestions }) => (
	<div className={classNames(
		"assessment-question",
		{
			active,
			hasValue: answer !== null,
			skipped
		}
	)}>
		<p className="fw-semibold py-2" data-question-id={question.id}>{question.text}</p>
		<AssessmentQuestionScale
			disabled={disabled}
			id={question.id}
			responseLabels={responseLabels}
			updateQuestions={updateQuestions}
			value={answer}
		/>
	</div>
);

AssessmentQuestion.propTypes = {
	active: PropTypes.bool,
	answer: PropTypes.number,
	disabled: PropTypes.bool,
	question: PropTypes.shape({
		id: PropTypes.number.isRequired,
		response: PropTypes.number,
		text: PropTypes.string.isRequired
	}).isRequired,
	responseLabels: PropTypes.arrayOf(PropTypes.object).isRequired,
	skipped: PropTypes.bool,
	updateQuestions: PropTypes.func.isRequired
};

AssessmentQuestion.defaultProps = {
	active: false,
	answer: null,
	disabled: false,
	skipped: false
};

export default AssessmentQuestion;
