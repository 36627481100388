/* eslint-disable */
import axios from "axios";
import { isString } from "lodash";
import { initReactQueryAuth } from "react-query-auth";

import Loader from "../../components/Loader.jsx";
import { graphql } from "../../services/wp.api.service.js";

const maxLoginLength = 1000 * 60 * 60 * 24;

/**
 * User Fragment
 *
 * GraphQL User Fragment to reuse in other calls
 */
const userFragment = `
	fragment UserFragment on User {
		id
		databaseId
		email
		firstName
		lastName
		roles {
			nodes {
				name
			}
		}
		respondents {
			edges {
				node {
					respondentId: databaseId
					consentPrivacy
					profileComplete
					weqProfiles {
						edges {
							node {
								databaseId
								reportUri
							}
						}
					}
					practitioner {
						node {
							name
							author {
								node {
									email
								}
							}
							practitionerAcfFields {
								phone
							}
						}
					}
				}
			}
		}
		jwtRefreshToken
		jwtAuthToken
		jwtAuthExpiration
	}
`;

/**
 * Helper method to decode HTML strings from the API
 * @param {*} input - string containing HTML to be parsed
 * @returns 
 */
const htmlDecode = (input) => {
	const doc = new DOMParser().parseFromString(input, "text/html");
	return doc.documentElement.textContent;
  }

/**
 * Transform Returned User Object
 *
 * Transforms the graphql output to the shape we want to consume it in.
 *
 * @param {object} user The user
 */
const transformUser = (user) => {
	user.roles = user.roles.nodes.map((role) => role.name === "administrator" ? "staff" : role.name);
	
	if (user.roles.includes("practitioner")) {
		user.practitionerId = user.practitioners?.edges[0]?.node?.practitionerId || null;
		user.practitionerID = user.practitioners?.edges[0]?.node?.practitionerID || null;
		user.pricingTier = user.practitioners?.edges[0]?.node?.practitionerAcfFields?.pricingtier;
		user.status = user.practitioners?.edges[0]?.node?.practitionerAcfFields?.practitionerstatus || "invited";
		const { featuredImage } = user.practitioners.edges[0].node;
		user.photo = featuredImage ? featuredImage.node.sourceUrl : null;
		user.profileCompleted = user.practitioners?.edges[0]?.node?.practitionerAcfFields?.profileCompleted || false;
		user.credits = user.practitioners?.edges[0]?.node?.credits;
	}

	if (user.roles.includes("respondent")) {
		// eslint-disable-next-line no-param-reassign
		user = {
			...user,
			...user.respondents.edges[0].node
		};

		const respProfile = user.respondents?.edges[0]?.node;

		if ( respProfile.weqProfiles?.edges && respProfile?.weqProfiles?.edges?.length ) {
			user.weqProfileId = respProfile.weqProfiles.edges[0].node.databaseId;
			user.weqProfilePdfUrl = respProfile.weqProfiles.edges[0].node.reportUri;
		}
	}

	return user;
};

/**
 * Stores the local user auth session in local storage.
 * 
 * @param {*} user - user fragment returned from API
 * @param {*} authToken - authentication token provided by the API
 * @param {*} refreshToken - refresh token provided by the API
 */
const setUserSession = (user, authToken, refreshToken) => {
	const userAuth = {
		email: user.email,
		refreshToken,
		role: user.roles.nodes[0].name,
		token: authToken
	};
	localStorage.setItem("user", JSON.stringify(userAuth));
	localStorage.setItem("lastLoginTime", new Date().getTime());
	localStorage.setItem("jwtAuthExpiration", parseInt(user.jwtAuthExpiration, 10) * 1000);
};

/**
 * The API Base
 */
const API_URL = process.env.REACT_APP_API_URL || ""; /* eslint-disable-line no-undef */

/**
 * Generate Auth Header
 *
 * Generates the auth headers necessary for an API call to WordPress.
 * First detects to see if the wpApiSettings variable is set and then
 * falls back to using JWT.
 *
 * @returns object
 */
export const authHeader = () => {
	const user = JSON.parse(localStorage.getItem("user"));

	const headers = {};

	const { wpApiSettings } = window;
	if (wpApiSettings && wpApiSettings.nonce) {
		headers["X-WP-Nonce"] = wpApiSettings.nonce;
	} else if (user && user.token) {
		headers.Authorization = `Bearer ${user.token}`;
	}

	return headers;
};

/**
 * Detect if the current user is logged in as a WP user.
 * 
 * @returns bool
 */
export const isWP = () => !!window?.wpApiSettings?.nonce;

export const loginGraphQL = `
${userFragment}
mutation LoginUser($username: String!, $password: String!) {
	login(
		input: {
			clientMutationId: "uniqueId",
			username: $username,
			password: $password
		}
	) {
		authToken
		refreshToken
		user {
			...UserFragment
		}
	}
}
`;

/**
 * WordPress JWT Login Function
 *
 * Requests a new JWT token and then gets the user data.
 *
 * @param {email, password} Credentials The login credentials.
 * @returns Promise
 */
const loginFn = ({ email, password }) => {
	const loginUrl = `${API_URL}/graphql`;

	const query = `
		${userFragment}
		mutation LoginUser($username: String!, $password: String!) {
			login(
				input: {
					clientMutationId: "uniqueId",
					username: $username,
					password: $password
				}
			) {
				authToken
				refreshToken
				user {
					...UserFragment
				}
			}
		}
	`;

	return axios.
		post(loginUrl, {
			query,
			variables: {
				password,
				username: email
			}
		}).
		then((res) => {
			if (res.status === 200) {
				if (res.data?.data?.login) {
					const { user, authToken, refreshToken } = res.data.data.login;
					const [userRole] = user.roles.nodes;

					if (["administrator", "staff"].indexOf(userRole.name) >= 0 && window.location.host.search("localhost") === -1) {
						window.location.reload();
					} else {
						setUserSession(user, authToken, refreshToken);
						return transformUser(user);
					}
				} else {
					localStorage.removeItem("user");
					localStorage.removeItem("jwtAuthExpiration");
					throw new Error("Bad login credentials.");
				}
			} else {
				localStorage.removeItem("user");
				throw new Error("Please check your login credentials. If this is your first time logging into the new portal, please reset your password via the \"Forgot Password\" link below.");
			}
		}).
		catch((error) => {
			console.log(error);
			localStorage.removeItem("user");
			localStorage.removeItem("jwtAuthExpiration");
			throw new Error("Please check your login credentials.");
		});
};

/**
 * WordPress JWT Logout Function
 *
 * Logs the user out.
 *
 * @todo Log user out of WordPress.
 *
 * @returns Promise
 */
const logoutFn = () => new Promise((resolve) => {
	localStorage.removeItem("user");
	localStorage.removeItem("lastLoginTime");
	localStorage.removeItem("jwtAuthExpiration");

	if (window.wpApiSettings) {
		window.location.href = `/wp-login.php?action=logout&_wpnonce=${window.wpApiSettings.logoutNonce}`;
	}

	resolve(false);
});

export const loadUserGraphQL = `
${userFragment}
query MyQuery( $id: ID! ) {
	user(id: $id, idType: EMAIL) {
		...UserFragment
	}
}
`;

/**
 * WordPress Load User Function
 *
 * Used to get the latest user data. This function is used by
 * React Query to get the latest server user state.
 *
 * @returns Promise
 */
const loadUser = () => {

	let user = window.localStorage.getItem("user") || null;

	if ( window.wpApiSettings ) {
		user = window.wpApiSettings;
	}

	if (user) {

		user = isString(user) ? JSON.parse(user) : user;
		const query = `
			${userFragment}
			query MyQuery( $id: ID! ) {
				user(id: $id, idType: EMAIL) {
					...UserFragment
				}
			}
		`;

		return graphql({
			query,
			variables: {
				id: user.email
			}
		}).
			then((res) => {
				if (res.data?.data?.user) {

					if (!window.wpApiSettings) {
						const {
							email,
							jwtAuthExpiration,
							jwtAuthToken: token,
							jwtRefreshToken: refreshToken,
							roles
						} = res.data.data.user;
						const userAuth = {
							email: email,
							refreshToken,
							role: roles.nodes[0].name,
							token
						};
						localStorage.setItem("user", JSON.stringify(userAuth));
						localStorage.setItem("jwtAuthExpiration", parseInt(jwtAuthExpiration, 10) * 1000);
					}

					return transformUser(res.data.data.user);
				}
				return false;
			}).
			catch((error) => {
				console.log(error);
			});
	}

	return false;
};

/* eslint-disable */
/**
 * WordPress Register Function
 * 
 * @todo Write Registration Function
 * 
 * @returns Promise
 */
export const registerFn = ({ email, password }) => {
	const registerUrl = `${API_URL}/graphql`;
	// tried to query for auth and refresh tokens here, but the API returned an error
	// unsure how to authenticate the newly-registered user
	const query = `
	${userFragment}
	mutation MyMutation($password: String = "", $username: String = "", $email: String = "") {
		registerUser(
			input: {
				username: $username,
				password: $password,
				email: $email
			}
		) {
			user {
				...UserFragment
			}
		}
	}`;

	// transform username from email to appease API
	const username = email.split('@')[0].replace('+', '_');

	return axios.
		post(registerUrl, {
			query,
			variables: {
				email,
				password,
				username
			}
		}).
		then((res) => {
			if (res?.data?.errors) {
				throw new Error(htmlDecode(res?.data?.errors[0].message));
			}
			if (res?.data?.data?.registerUser) {
				const { user } = res?.data?.data?.registerUser;
				setUserSession(user, user.jwtAuthToken, user.jwtRefreshToken);
				return transformUser(user);
			}
			return false;
		}).
		catch((error) => {
			console.log(error);
			throw new Error(error);
		});
};

/**
 * Request Password Reset Query Function
 * 
 * @param function useMutation The useMutation hook.
 * @param object props Custom mutation properties.
 * @returns Promise
 */
export const requestPasswordResetFn = (useMutation, props) => {
	const query = `
	mutation RequestReset($username: String = "") {
		sendPasswordResetEmail(input: {username: $username}) {
			clientMutationId
		}
	}
	`;

	return useMutation(
		(username) => graphql({
			query,
			variables: {
				username
			}
		}, true),
		props
	);
};

/**
 * Reset Password Query Function
 * 
 * @param function useMutation The useMutation hook.
 * @param object props The custom mutation props.
 * @returns Promise
 */
export const resetPasswordFn = (useMutation, props) => {
	const query = `
	mutation ResetPassword($key: String = "", $login: String = "", $password: String = "") {
		resetUserPassword(input: {key: $key, login: $login, password: $password}) {
			user {
			email
			}
		}
	}
	`;

	return useMutation(
		(variables) => graphql({
			query,
			variables
		}, true),
		props
	);
}

export const submitSignInCodeFn = () => {
	console.log("Should submit 2fa code here");
	return Promise.resolve(true);
};

/**
 * Generate Auth Provider
 *
 * Using React Query Auth npm package to generate the AuthProvider
 * and useAuth hook.
 *
 * Any component that needs to reference the current user can get it from the
 * useAuth() hook via destructuring. i.e. const { user } = useAuth()
 *
 * @see https://www.npmjs.com/package/react-query-auth
 */
export const { AuthProvider, useAuth } = initReactQueryAuth({
	LoaderComponent: Loader,
	loadUser,
	loginFn,
	logoutFn,
	registerFn
});

/**
 * Determine If User Should Refresh JWT Token
 * @returns Boolean
 */
export const shouldRevalidateToken = () => {

	if ( window.wpApiSettings ) {
		return false;
	}

	const lastLogin = parseInt(localStorage.getItem("lastLoginTime"), 10);
	const jwtAuthExpiration = parseInt(localStorage.getItem("jwtAuthExpiration"), 10);
	const now = new Date().getTime();

	if (!jwtAuthExpiration || now > jwtAuthExpiration || now - lastLogin >= maxLoginLength) {
		return true;
	}

	return false;
};

/**
 * Revalidate JWT Auth Token
 *
 * Handles revalidating user token if they have chosen to be
 * kept logged in.
 *
 * @returns Promise
 */
export const revalidateToken = () => {

	const controller = new AbortController();
	let user = false;
	try {
		user = JSON.parse(localStorage.getItem("user"));
	} catch (error) {
		return false;
	}

	if (!user.refreshToken) {
		return false;
	}

	const query = `
		mutation MyMutation($jwtRefreshToken: String = "") {
			refreshJwtAuthToken(input: {jwtRefreshToken: $jwtRefreshToken}) {
				authToken
			}
		}
	`;

	return graphql({
		query,
		variables: {
			jwtRefreshToken: user?.refreshToken
		}
	}, true, {
		signal: controller.signal
	}).
		then(({ data: { data } }) => {
			if ( data.refreshJwtAuthToken ) {
				user.token = data.refreshJwtAuthToken.authToken;
				localStorage.setItem("user", JSON.stringify(user));
				return true;
			}
			return false;
		});
};
