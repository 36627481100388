/* eslint-disable max-len, max-statements, no-unused-vars, no-console */
import * as yup from "yup";
import { Accordion, Button, Container, Form } from "react-bootstrap";
import { useCallback, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import EqqoVideo from "./EqqoVideo";
import { Formik } from "formik";
import InstructionsText from "./InstructionsText";
import PropTypes from "prop-types";
import { updateWeqProfileMutation } from "../../services/wp.weqprofile.mutation";
import { useNavigate } from "react-router-dom";
import { useUser } from "../UserContext";

const Instructions = ({ readInstructions }) => {
	const navigate = useNavigate();
	const { user, userProfile } = useUser();
	const instructionVideoID = "r7sojqvth9";
	// Track if video is finished, i.e. has been watched
	const [videoFinished, setVideoFinished] = useState(false);
	const onProgress = useCallback((progress) => {
		if (progress.played >= 0.99) {
			setVideoFinished(true);
		}
	}, []);

	const queryClient = useQueryClient();
	const {
		mutateAsync: updateProfile
	} = updateWeqProfileMutation(useMutation);

	const handleFormSubmit = useCallback(async (values) => {
		const input = {
			id: user.weqProfileId,
			weqProfileAcfFields: {
				profilestatus: "inProgress",
				readInstructions: !!values.readInstructions.length
			}
		};

		try {
			await updateProfile(input);
			await queryClient.refetchQueries(["respondent", user.respondentId]);
			navigate(`/profile/assessment/1`);
		} catch (error) {
			console.log(error);
		}
	});
	const requiredMessage = "Please acknowledge that you understand these instructions.";
	const validationSchema = yup.object().shape({
		readInstructions: yup.array(yup.string("read"), requiredMessage).length(1, requiredMessage).
			required(requiredMessage)
	});

	return (
		<div className="profile__instructions">
			{ !readInstructions &&
				<h2>Hi {userProfile?.firstName}! Please watch the video for instructions.</h2> }
			{ readInstructions && <>
				<h2>Welcome back {userProfile?.firstName}!</h2>
				<p>Your EQQO Profile assessment is in progress. You may review the instructions below if you need a refresher!</p>
			</>}
			<Container className="mt-4 video px-0">
				<EqqoVideo
					className="d-block w-100 mw-100"
					config={{
						wistia: {
							options: {
								playbackRateControl: false,
								playbar: process.env.REACT_APP_SHOW_SCROLL_BAR || false,
								playerColor: "000000",
								plugins: {
									"captions-v1": {
										onByDefault: true
									}
								},
								preload: true,
								videoFoam: true
							}
						}
					}}
					onProgress={onProgress}
					style={{ aspectRatio: "16 / 9" }}
					url={`https://fast.wistia.net/embed/iframe/${instructionVideoID}`}
				/>
			</Container>
			<Accordion className="mt-4" defaultActiveKey="0">
				<Accordion.Item eventKey="1">
					<Accordion.Header>Read the Instructions</Accordion.Header>
					<Accordion.Body>
						<InstructionsText />
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
			{ !readInstructions && (
				<Formik
					initialValues={{ readInstructions: [] }}
					onSubmit={handleFormSubmit}
					validationSchema={validationSchema}
				>
					{({
						errors,
						handleBlur,
						handleChange,
						handleSubmit,
						touched,
						values
					}) => (
						<Form className="mt-4" onSubmit={handleSubmit}>
							<Form.Group className="container my-3" controlId="readInstructions">
								<Form.Check.Input
									isInvalid={touched.readInstructions && errors.readInstructions}
									name="readInstructions"
									onBlur={handleBlur}
									onChange={handleChange}
									value="read"
								/>
								<Form.Check.Label className="d-inline mx-2">
									{/* eslint-disable-next-line max-len */}
									I have watched and/or read the instructions and I understand that I am to respond not in my current professional role, but in the roles suggested before each video.
								</Form.Check.Label>
								{errors.readInstructions && (
									<Form.Control.Feedback
										className="my-2"
										type="invalid"
									>
										{errors.readInstructions}
									</Form.Control.Feedback>)}
							</Form.Group>
							<div className="actions text-center mt-4">
								<Button className="btn-gradient w-100" type="submit">
									Start Assessment <ArrowRightIcon height={24} width={24} />
								</Button>
							</div>
						</Form>)}
				</Formik>
			)}
		</div>
	);
};

Instructions.propTypes = {
	readInstructions: PropTypes.bool,
};

Instructions.defaultProps = {
	readInstructions: false
}

export default Instructions;
