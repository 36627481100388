/* eslint-disable max-len, no-console */
/* eslint-disable react/no-unescaped-entities */
import { Col, Container, Row } from "react-bootstrap";
import ForgotPasswordForm from "./ForgotPasswordForm";
import Helmet from "react-helmet";
import { NavLink } from "react-router-dom";

const ForgotPassword = () => (
	<Row as="main" className="sign-up">
		<Helmet>
			<title>
				{"Create Your EQQO Account"}
			</title>
		</Helmet>

		<Col className="create-account flow" fluid="true">
			<img
				alt="LOGO"
				className="logo"
				height="152"
				src="/eqqo_logo_blue.png"
				width="472"
			/>
			<Container className="bg-white text-center wrapper">
				<h1>Forgot your Password?</h1>
				<p>
					Don't worry, it happens to all of us.
				</p>
				<ForgotPasswordForm />
				<p className="mt-5">Remembered Your Password?</p>
				<NavLink to="/sign-in">Sign In Instead</NavLink>
			</Container>
		</Col>
	</Row>
);

export default ForgotPassword;
