/* eslint-disable max-len, react/no-unescaped-entities */

const InstructionsText = () => (<>
	<h4 className="text-blue fw-bold mt-3 mb-3">WELCOME</h4>
	<p>
		Welcome to the Learning In Action EQQO Profile. The EQQO Profile was created
		to support the development of healthy relationships by illuminating the lens
		with which we see ourselves, others and the world and highlighting the
		unconscious patterns that impact our ability to stay connected with others. We
		hope you find it both insightful and meaningful.
	</p>
	<p>
		Instructions for completing this instrument are described below. You DO NOT
		need to be familiar with relational intelligence to take this instrument.
	</p>

	<h4 className="text-blue fw-bold mt-4 mb-3">HOW TO PREPARE</h4>
	<p>
		Before beginning, you will want to set aside 45 minutes of uninterrupted time
		to complete the EQQO Profile. While you may stop and save your work at any
		time, we strongly recommend that you complete it in a single sitting.
	</p>
	<p>
		If you are feeling poorly or are distracted, wait until you are feeling better
		and more focused before you continue. You’ll get the truest results by
		focusing your full attention on the experience.
	</p>
	
	<h4 className="text-blue fw-bold mt-4 mb-3">WHAT YOU’LL EXPERIENCE</h4>
	<p>You’ll be presented with:</p>
	<ul>
		<li>Eight video segments of common workplace situations.</li>
		<li>
			Someone talking to you in each video as your manager, your direct report or
			your peer.
		</li>
		<li>
			Statements following each video which are reflective of one’s internal
			experience as a result of that situation.
		</li>
	</ul>
	
	<h4 className="text-blue fw-bold mt-4 mb-3">YOUR TASK</h4>
	<p>As much as possible:</p>
	<ul>
		<li>Imagine yourself in the situation presented in the video.</li>
		<li>Imagine that you know the person in the video.</li>
		<li>Respond as if it is happening to you right now.</li>
		<li>
			Respond as if the situation is real for you and you are in the role
			suggested.
		</li>
		<li>
			Respond authentically and as yourself (vs how you think you “should” or in
			your current professional role).
		</li>
	</ul>
	<p>
		If you’ve never lived through these situations or in the roles of boss,
		subordinate or peer, imagine as best you can that you are in that position and
		situation now. It is critical that you put yourself into the situation and
		report your experience as if it were real and actually happening to you in the
		moment.
	</p>
	
	<h4 className="text-blue fw-bold mt-4 mb-3">GETTING STARTED</h4>
	<ol>
		<li>
			<p>
				Start: Click the blue "Get Started" bar underneath the welcome video or at
				the bottom of these instructions.
			</p>
		</li>
		<li>
			<p>
				Navigation Bar: At the top of your screen, a blue progress bar will
				appear. It will show you how far along you are in completing the
				instrument.
			</p>
		</li>
		<li>
			<p>
				Watch Each Video: You’ll get the truest results by focusing your full
				attention on the videos. If you are feeling poorly or are distracted,
				you’ll want to wait until you are feeling better and more focused before
				you continue. When you are ready to begin click the Play button on the
				video (the forward arrow on the bottom left corner of the video or by
				clicking on the video itself).
			</p>
		</li>
		<li>
			<p>
				Rating Form: After watching each video, click on the "RATE MY EXPERIENCE"
				button found below the video to move onto the rating form. You’ll rate a
				series of statements based upon the extent to which they reflect your
				internal experience.
			</p>
		</li>
		<li>
			<p>
				Submit Ratings:<br />
				Rate the statements listed after each video considering the following:
			</p>
			<ul>
				<li>
					Rate, on a scale of 1-5, the extent to which the statement reflects your
					internal experience based on the situation presented in the video.
				</li>
				<li>Statements that in no way fit your experience, rate a 1.</li>
				<li>Statements that totally fit your experience, rate a 5.</li>
				<li>Rate thoughtfully but quickly.</li>
				<li>Go with your first reaction, and what rings true for you.</li>
				<li>
					You may or may not have thought of the specific words that are listed.
					Go with how closely the statement fits your experience.
				</li>
				<li>
					After completing the rating form, click the blue bar at the bottom of
					the screen to move onto the next scenario. The rating form will display
					any missed rating(s) in red. You will not be able to move to the next
					scenario until all ratings are complete.
				</li>
			</ul>
		</li>
		<li className="mt-3">
			<p>
				After rating the eighth video, a completion message will appear. Read the message, then 
				click "Continue to Your EQQO Profile" to download your report and explore how to make the most of your results.
			</p>
		</li>
	</ol>
	<p>At the conculsion of the experience, your EQQO Profile will be generated and available for you to review.</p>
	<p>
		You will also be given the opportunity to purchase a debrief session with an EQQO Certified Practitioner, which will enable you to further explore your EQQO, the history and stories behind it, how it impacts you in conflict, and what you can do about it.
	</p>
	<p>
		<em>Closed Captions available please email</em> <a href="mailto:support@learninginaction.com">support@learninginaction.com</a>
	</p>
</>);

export default InstructionsText;
