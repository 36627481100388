/* eslint-disable camelcase, capitalized-comments, lines-around-comment, no-console, no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import { Button, Form, Spinner } from "react-bootstrap";
import { useCallback, useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { Formik } from "formik";

const JournalPrompts = () => {
	const setInitialValues = useMemo(() => {
		const values = {
			prompt1: "",
			prompt2: "",
			prompt3: "",
			prompt4: "",
			prompt5: "",
		};
		return values;
	}, []);

	const [isLoading, setIsLoading] = useState(false);

	const handleFormSubmit = useCallback((values) => {
		setIsLoading(true);
		// submit journal answers
		console.log(values);
		setIsLoading(false);
	});
	return (
		<Formik
			initialValues={setInitialValues}
			onSubmit={handleFormSubmit}
		>
			{(formik) => (
				<Form noValidate onSubmit={formik.handleSubmit}>
					<Form.Label>Prompt 1</Form.Label>
					<Form.Control
						as="textarea"
						name="prompt1"
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						rows={4}
					/>

					<Form.Label className="mt-4">Prompt 2</Form.Label>
					<Form.Control
						as="textarea"
						name="prompt2"
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						rows={4}
					/>

					<Form.Label className="mt-4">Prompt 3</Form.Label>
					<Form.Control
						as="textarea"
						name="prompt3"
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						rows={4}
					/>

					<Form.Label className="mt-4">Prompt 4</Form.Label>
					<Form.Control
						as="textarea"
						name="prompt4"
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						rows={4}
					/>

					<Form.Label className="mt-4">Prompt 5</Form.Label>
					<Form.Control
						as="textarea"
						name="prompt5"
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						rows={4}
					/>

					<Button
						className="btn-primary mt-4 w-100"
						disabled={isLoading}
						onClick={formik.handleSubmit}
						type="submit"
						variant="primary"
					>
						{
							isLoading
								? <Spinner animation="border" size="sm" />
								: <>Save <ArrowRightIcon height={20} width={20} /></>
						}
					</Button>
				</Form>
			)}
		</Formik>
	);
};

export default JournalPrompts;
